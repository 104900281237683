import React from "react"
import Layout from "@Components/Layout"
import Contact from "@Components/Contact"
import SEO from "@Components/SEO"

export default function ContactPage() {
  return (
    <Layout menuOpen={true} menuVisible={true} location={"contact"} textIsRed={false}>
      <SEO title="Contact" />
      <Contact startTypingText={true} />
    </Layout>
  )
}